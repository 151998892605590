import ManageCompanies from "./ManageCompanies";
import Routes from "./routes";

let ManageCompaniesModule = {
    path: "manage-companies",
    component: ManageCompanies,
    meta: {
        label: "Manage Companies",
        include: true,
        logRouteAccess: true,
    },
    children: Routes
};

export default ManageCompaniesModule;
