<template>
    <router-view v-slot="{ Component }">
        <keep-alive include="CompanyContactList">
            <component
                :is="Component"
                @company-update-success="goToList"
                @company-cancel-add="goToList"
                @back-to-company-list="goToListTab(0)"
                @view-company-contacts="viewCompanyContacts"
                @view-company-detail="goToCompanyDetail"
                @view-contact-detail="goToContactDetail"
                @contact-update-success="goToList"
                @contact-cancel-add="goToList"
            />
        </keep-alive>
    </router-view>
</template>

<script>
    export default {
        name: "ManageCompanies",
        data() {
            return {
                routePrefix: "dashboard:mc:"
            };
        },
        methods: {
            goToList(params) { this.$router.push({ name: `${this.routePrefix}company-contact-list`, params }); },
            goToListTab(tabIndex) { this.goToList({ tabIndex }); },
            viewCompanyContacts(targetCompany) { this.goToList({targetCompanyRegionId: targetCompany.regionId, targetCompanyId: targetCompany.companyId, targetCompanyName: targetCompany.companyName, targetCompanySalesRepStaffId: targetCompany.companySalesRepStaffId }); },
            goToCompanyDetail(params) { this.$router.push({ name:`${this.routePrefix}company`, params }); },
            goToContactDetail(params) { this.$router.push({ name: `${this.routePrefix}contact`, params }); }
        }
    }
</script>

