<template>
    <rq-section-card title="Underwriter" section-group="company-edit" collapsible>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_code">Code</label>
                    <input id="txt_code" automation_id="txt_code" type="text" class="form-control" maxlength="8" placeholder="Code" v-model="modelValue.code">
                </div>
                <div class="col col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_additionalCode">Additional Code</label>
                    <input id="txt_additionalCode" automation_id="txt_additionalCode" type="text" class="form-control" maxlength="4" placeholder="Additional Code" v-model="modelValue.additionalCode">
                </div>
                <div class="col col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_report_code">Report Code</label>
                    <input id="txt_report_code" automation_id="txt_report_code" type="text" class="form-control" maxlength="15"  placeholder="Report Code" v-model="modelValue.reportCode">
                </div>
                <div class="col col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_full_name">Full Name</label>
                    <input id="txt_full_name" automation_id="txt_full_name" type="text" class="form-control" maxlength="200" placeholder="Full Name" v-model="modelValue.fullName">
                </div>
                <rq-action-form-group
                    label="Default Split Percentage"
                    label-for="txt_default_split_percentage"
                    class="col col-12 col-md-6 col-lg-3"
                    :class="{'has-error' :this.v$.$error }"
                    show-action>
                    <template #action>
                        <b-form-checkbox id="chk_create_check" automation_id="chk_create_check" v-model="modelValue.createCheck">Create Check</b-form-checkbox>
                    </template>
                    <rqInputNumber
                        id="txt_default_split_percentage"
                        defaultValue="0"
                        decimals="3"
                        append-text="%"
                        text-align="right"
                        v-model="modelValue.defaultSplitPercentage"
                        input-group
                    />
                    <rq-validation-feedback :offset="35"
                    :messages="{
                        ['Percentage cannot exceed 100']:this.v$.$error,
                    }"
                />
                </rq-action-form-group>
            </div>
        </fieldset>
        <schedule-d-section
            entity-name="Underwriter"
            editor-title="Edit Underwriter Schedule D"
            v-model:rtf="modelValue.scheduleD"
            v-model:html="modelValue.scheduleDHtml"
            :readOnly="readOnly"
        />
    </rq-section-card>

    <rq-section-card title="Agency IDs" section-group="company-edit" collapsible>
        <underwriter-agency-ids
            ref="underwriterAgencyIds"
            v-bind:underwriter-agencies="modelValue.underwriterAgencies"
            @validation-event="onValidationEvent"
            :read-only="readOnly"
        />
    </rq-section-card>
    <rq-section-card title="Agency Premium Split" section-group="company-edit" collapsible>
        <underwriter-premium-split
            ref="underwriterPremiumSplits"
            v-bind:underwriter-premium-splits="modelValue.underwriterPremiumSplits"
            @validation-event="onValidationEvent"
            :read-only="readOnly"
        />
    </rq-section-card>

</template>
<script>
    import { Underwriter } from '@order-entry/contacts/models';
    import ScheduleDSection from "./ScheduleDSection";
    import { useVuelidate } from "@vuelidate/core";
    import UnderwriterAgencyIds from "./UnderwriterAgencyIds.vue";
    import UnderwriterPremiumSplit from "./UnderwriterPremiumSplit.vue";

    export default {
        name: "UnderwriterCompanyInfo",
        props: {
            modelValue: { type: Underwriter, default: () => new Underwriter() },
            readOnly: { type: Boolean, default: false }
        },
        setup: () => ({ v$: useVuelidate() }),
        computed: {
            tooltipContainer() { return `#${this.dialogId}`; },

            underwriterAgencies(){ return this.$refs.underwriterAgencyIds.items; },
            originalUnderwriterAgencies(){ return this.$refs.underwriterAgencyIds.originals; },

            underwriterPremiumSplits(){ return this.$refs.underwriterPremiumSplits.items; },
            originalUnderwriterPremiumSplits(){ return this.$refs.underwriterPremiumSplits.originals; }
        },
        components: {
            ScheduleDSection,
            UnderwriterAgencyIds,
            UnderwriterPremiumSplit
        },
        methods: {
            onValidationEvent(e){
                const self = this;
                self.$emit('validation-event', e);
            },
            validate() {
                const self = this;
                self.v$.$touch();
                return self.$refs.underwriterAgencyIds.isValid
                       && self.$refs.underwriterPremiumSplits.isValid
                       && !self.v$.$error;
            }
        },
        validations: () => ({
            modelValue: {
                defaultSplitPercentage: {
                    isInRange(val) {
                        if(val == 100){
                            return true
                        }
                        else
                            return _.inRange(_.parseNumber(val, 0), 0, 100) ;
                    }
                }
            }
        }),
    }
</script>