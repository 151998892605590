<template>
    <rq-page-section title="Schedule D" borderless>
        <template #header-actions>
            <ul class="nav">
                <li class="nav-item">
                    <b-btn
                        variant="theme"
                        size="sm"
                        :disabled="readOnly"
                        @click="onEditSchedD">Edit</b-btn>
                </li>
            </ul>
        </template>
        <div class="row">
            <div class="col-12 form-group">
                <rq-rtf-display
                    id="rtf_underwriter_sched_d"
                    ref="schedulDDisplay"
                    :min-height="200"
                    :max-height="500"
                    v-model="htmlValue"
                    adaptive
                    no-hover
                />
            </div>
        </div>
    </rq-page-section>
</template>
<script>
    import { DocumentEditorDialog } from "@/shared/components/rq";
    import { DocumentFileType } from '@/modules/documents/enums';
    import { WrapRtf } from "@/shared/models/models";

    export default {
        name: "ScheduleDSection",
        props: {
            entityName: { type: String, default: null },
            rtf: { type: String, default: null },
            html: { type: String, default: null },
            readOnly: { type: Boolean, default: false }
        },
        computed: {
            editorTitle() { return `Edit ${this.entityName} Schedule D`; },
            docName() { return `${_.snakeCase(this.entityName)}.rtf`; },
            htmlValue: {
                get() { return this.html; },
                set(val) { this.$emit("update:html", val); }
            },
            rtfValue: {
                get() { return this.rtf; },
                set(val) { this.$emit("update:rtf", val); }
            }
        },
        methods: {
            onEditSchedD() {
                const self = this;
                let rtfContent = self.rtfValue || WrapRtf("", true);
                self.launchEditorDialog(self.docName, rtfContent, DocumentFileType.RTF);

                // Not removing this entirely in case the DevEx editor starts doing weird stuff with RTF again
                // let apiPromise = self.$api.UtilitiesApi.rtfToDocx(rtfContent);
                // self.$rqBusy.wait(apiPromise)
                //     .then(docxResult => {
                //         self.launchEditorDialog(docxResult);
                //     });
            },

            launchEditorDialog(documentName, documentContent, fileType) {
                const self = this;
                self.$dialog.open({
                    title: self.editorTitle,
                    height: "95%",
                    width: "95%",
                    scrollable: false,
                    draggable: false,
                    component: DocumentEditorDialog,
                    props: {
                        documentName,
                        documentContent,
                        fileType,
                        showInsertDocLink: true
                    },
                    onOk(e) {
                        return e.component.getContent({ html:true, rtf:true, docx: false, fragment: false })
                            .then(result => {
                                if(!result.changed) return true;
                                self.rtfValue = btoa(result.rtf);
                                self.htmlValue = result.html;
                                return true;
                            });
                    }
                });
            }
        }
    }
</script>