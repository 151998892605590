<template>
    <div class="company-edit content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && (!isFormValid || !customDataIsValid)"
        />
        <rq-page-section header-size="lg" header-border scrollable borderless custom-title>
            <template #header-title>
                <div v-shave="{ height: 60 }" class="company-title" :title="companyTitle">{{companyTitle}}</div>
            </template>
             <template #header-actions>
                <ul v-if="!hasCompanyAccess" class="nav">
                    <li class="nav-item">
                        <span class="badge badge-danger rq-section-badge">Company is not in your region</span>
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item form-group">
                        <b-form-checkbox id="chk_preferred"
                            automation_id="chk_preferred"
                            :disabled="!hasCompanyAccess || readOnly"
                            v-model="contactDetail.contactInfo.isPreferred">Preferred
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item form-group">
                        <b-form-checkbox id="chk_inactive"
                            automation_id="chk_inactive"
                            :disabled="!hasCompanyAccess || readOnly"
                            v-model="contactDetail.contactInfo.isInactive">Inactive
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item form-group">
                        <b-form-checkbox id="chk_defaultServiceContact"
                            automation_id="chk_defaultServiceContact"
                            :disabled="!hasCompanyAccess || readOnly"
                            v-model="contactDetail.contactInfo.isDefaultServiceContact">Default Service Contact
                        </b-form-checkbox>
                    </li>
                </ul>
                <ul v-if="showActions && hasCompanyAccess" class="nav config-actions">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancelAction">CANCEL</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            v-if="addMode"
                            @click="onSaveAndAddAnotherClicked">SAVE AND ADD ANOTHER</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSaveClicked">SAVE</b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-title rq-title-lg contact-title px-0">{{contactTitle}}</div>
                <div class="action-links">
                    <b-button
                        class="btn btn-link btn-theme"
                        automation_id="lnk_contact_view_company_details"
                        v-if="(showCompanyLinks || showOrderLinks) && !addMode"
                        @click="onViewCompanyDetails">
                        View Company Details
                    </b-button>
                    <b-button
                        class="btn btn-link btn-theme"
                        automation_id="lnk_contact_view_company_contacts"
                        v-if="(showCompanyLinks || showOrderLinks) && !addMode"
                        @click="onViewCompanyContactsClick">
                        View Company Contacts
                    </b-button>
                    <b-button
                        class="btn btn-link btn-theme"
                        automation_id="lnk_contact_view_order_contacts"
                        v-if="showOrderLinks"
                        @click="onViewOrderContactsClick">
                        View File Contacts
                    </b-button>
                    <b-button
                        class="btn btn-link btn-theme"
                        automation_id="lnk_contact_view_order_contacts"
                        v-if="showSettlementLinks"
                        @click="onViewSettlementContacts">
                        View CDF Contacts
                    </b-button>
                </div>
            </template>

            <contact-info v-model:contact="contactDetail.contactInfo"
                ref="contactInfo"
                :hasCompanyAccess="hasCompanyAccess"
                :companyTitle="companyTitle"
                :read-only="readOnly"
                @validation-event="onValidationEvent"
            />

            <!-- <fieldset :disabled="!allowSetPaperlessCloserSecurity || !hasCompanyAccess">
                <contact-pc-settings ref="contactPCSettings"
                    :pcSettings="contactDetail.paperlessCloserAccess"
                    :cdfPcSettings="contactDetail.cdfPaperlessCloserAccess"
                    @resetPassword="onPcSettingsResetPassword" />
            </fieldset> -->

            <rq-page-section title="Note" borderless collapsible>
                <div class="form-group">
                    <textarea ref="noteInput"
                        id="ta_contact_notes"
                        automation_id="ta_contact_notes"
                        placeholder="Enter notes here..."
                        class="form-control"
                        rows="10"
                        :disabled="!hasCompanyAccess || readOnly"
                        v-model="contactDetail.contactInfo.notes"
                    />
                </div>
            </rq-page-section>
            <rq-page-section title="Custom Data" collapsible borderless v-if="hasRolesId">
                <custom-data-container
                    ref="customDataContainer"
                    :reference-table="referenceTable"
                    :reference-table-pk-value="rolesId"
                    :custom-data-tab-id="customDataTabID"
                    v-model:value="customData"
                    v-model:is-valid="customDataIsValid"
                    :read-only="customDataReadOnly"
                    @custom-data-loaded="onCustomDataLoaded"
                ></custom-data-container>
            </rq-page-section>
        </rq-page-section>
    </div>
</template>
<script>
    import { GlobalEventManager } from "@/app.events";
    import { mapGetters, mapState } from "vuex";
    import ContactInfo from "./ContactInfo";
    import { Contact, PaperlessCloserAccess, CDFPaperlessCloserAccess } from '@order-entry/contacts/models';
    import { CustomDataReferenceTable } from "@/shared/components/customdata/models";
    import {  CustomDataContainer} from "@/shared/components";
    import { UserScreenAccessLevel } from "@/shared/models/enums";

    export default {
        components: { ContactInfo, CustomDataContainer},
        props: {
            showActions: { type: Boolean, default: false },
            showOrderLinks: { type: Boolean, default: false },
            showSettlementLinks: { type: Boolean, default: false },
            showCompanyLinks: { type: Boolean, default: false },
            modalMode: { type: Boolean, default: false },
            contactId: { type: Number, default: 0 },
            companyId: { type: Number, default: 0 },
            companyName: { type: String, default: "" },
            companySalesRepStaffId: { type: Number, default: null }
        },
        data: function() {
            return {
                contactDetail: {
                    contactInfo: new Contact(),
                    paperlessCloserAccess: new PaperlessCloserAccess(),
                    cdfPaperlessCloserAccess: new CDFPaperlessCloserAccess(),
                    resetPassword: false
                },
                hasCompanyAccess: false,
                companyTitle: "",
                contactName: "",
                allowSetPaperlessCloserSecurity: false,
                isFormValid: true,
                cancelNew: false,
                ignoreSave: false,
                showBanner: false,
                customDataTabID: 0,
                customDataIsValid: true,
                customData: [],
                originalCustomData: []
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                isSettlementLocked: state => _.parseBool(state.orders.orderSummary.isSettlementLocked),
            }),
            customDataReadOnly() { return this.localSecurity.AdditionalFields_ScreenAccess !== UserScreenAccessLevel.Full; },
            regions() { return this.lookupHelpers.getRegions(); },
            addMode() { return _.getNumber(this, "contactDetail.contactInfo.contactID", 0) === 0; },
            contactTitle() {
                if(this.addMode) return this.modalMode ? "Contact Details" : "Add Contact";
                let contactName = _.get(this, "contactDetail.contactInfo.fullName", "Contact Details");
                return this.modalMode ? contactName : `Edit Contact: ${contactName}`;
            },
            localSecurity(){ return this.securitySettings.findValues(["AllowEditContacts", "AdditionalFields_ScreenAccess"]); },
            hasOrderID() { return _.gt(_.getNumber(this, "$route.params.orderId", 0), 0); },
            readOnly() { return this.hasOrderID && (this.isFileLocked || this.isSettlementLocked); },
            referenceTable() {
                return CustomDataReferenceTable.OrderContacts;
            },
            rolesId() {
                return _.getNumber(this, "$route.params.rolesId", 0);
            },
            hasRolesId() { return this.rolesId > 0; }
        },
        watch: {
            "contactDetail.contactInfo.lastName": {
                handler: function(newValue, oldValue){
                    this.updateFullName();
                }
            },
            "contactDetail.contactInfo.firstName": {
                handler: function(newValue, oldValue){
                    this.updateFullName();
                }
            },
            reset: {
                handler: function(newValue, oldValue) {
                    if(newValue)
                        this.initializeContact();
                }
            }
        },
        created() {
            this.initializeEvents();
            this.initializeContact();
        },
        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },
        beforeRouteLeave(to, from, next) {
            const self = this;
            if(to && _.has(to.params, "orderId") && !to.params.orderId) {
                next();
                return;
            }

            // when user cancels creating new company or when user clicks save
            if(this.cancelNew || this.ignoreSave) {
                next();
            }
            else {
                let changes = [];
                if(self.originalContactDetail)
                    changes = self.getAuditChanges(self.originalContactDetail, self.contactDetail);

                if(changes.length > 0 || self.addMode) {
                    self.onSaveAction({
                        userInitiated: false
                    })
                    .then((canContinue) => {
                        if(canContinue)
                            next();
                    });
                }
                else {
                    next();
                }
            }
        },
        methods: {
            initializePermission() {
                this.hasCompanyAccess = this.hasAccess();
                this.allowSetPaperlessCloserSecurity = _.parseBool(this.securitySettings.findValue("AllowSetPaperlessCloserSecurity"));
            },
            initializeEvents() {
                GlobalEventManager.onSave(this, this.onSaveAction);
                GlobalEventManager.onCancel(this, this.onCancelAction);
            },
            initializeContact() {
                if(this.contactId && this.contactId > 0) {
                    this.fetchContactDetail(this.contactId);
                }
                else {
                    this.resetContactDetail();
                    this.contactDetail.contactInfo.companyID = this.companyId;
                    this.companyTitle = this.companyName;
                    this.contactDetail.contactInfo.salesRepresentativeStaffID = this.companySalesRepStaffId;
                    this.initializePermission();
                }
            },
            fetchContactDetail(contactId) {
                let self = this;
                let promise = self.$api.CompaniesApi
                    .getContactDetail(contactId)
                    .then(result => {
                        self.loadContactDetail(result);
                        self.initializePermission();
                    });

                self.$rqBusy.wait(promise);
            },
            loadContactDetail(data) {
                this.companyInfo = data.companyInfo;
                this.companyTitle = this.companyInfo.name;
                this.originalContactDetail = {
                    contactInfo: new Contact(data.contactInfo),
                    paperlessCloserAccess: new PaperlessCloserAccess(data.paperlessCloserAccess),
                    cdfPaperlessCloserAccess: new CDFPaperlessCloserAccess(data.cdfPaperlessCloserAccess),
                    resetPassword: false
                }

                this.contactDetail = {
                    contactInfo: new Contact(data.contactInfo),
                    paperlessCloserAccess: new PaperlessCloserAccess(data.paperlessCloserAccess),
                    cdfPaperlessCloserAccess: new CDFPaperlessCloserAccess(data.cdfPaperlessCloserAccess),
                    resetPassword: false
                }
            },
            onSaveAndAddAnotherClicked() {
                this.onSaveAction({
                    addAnother: true,
                    userInitiated: true
                });
            },
            onSaveClicked() {
                this.onSaveAction({
                    userInitiated: true
                });
            },
            onSaveAction(args={}){    
                if(!this.hasAccess || this.readOnly) return;             
                this.showBanner = true;
                this.$refs.contactInfo.validate();
                _.invoke(this, "$refs.customDataContainer.validate");
                if(!this.$refs.contactInfo.isValid || !this.customDataIsValid) return Promise.resolve(false);

                this.showBanner = false;
                return this.addMode
                    ? this.addContact(args)
                    : this.updateContact(args);
            },
            onCancelAction() {
                let self = this;
                _.invoke(self, "$refs.customDataContainer.resetFieldValidations");
                if(self.addMode)
                {
                    self.cancelNew = true;
                    self.$emit("contact-cancel-add");
                }
                else {
                    let changes = self.getAuditChanges(this.originalContactDetail, this.contactDetail);
                    if (changes.length > 0 || self.hasSelectedPCLChange || self.hasSelectedListCodeChange) {
                        self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", () => {
                            self.contactDetail = _.cloneDeep(self.originalContactDetail);
                        });
                    } else {
                        self.$toast.info("No Changes Detected.");
                    }
                }
            },
            onValidationEvent(e) {
                this.isFormValid = e.isValid;
            },
            onViewCompanyDetails() {
                this.$emit("view-company-detail", {
                    companyId: this.contactDetail.contactInfo.companyID
                });
            },
            onViewCompanyContactsClick() {
                this.$emit('view-company-contacts', {
                    companyId: this.contactDetail.contactInfo.companyID,
                    regionId: this.companyInfo.regionID,
                    contactId: this.contactDetail.contactInfo.contactID,
                    companyName: this.companyTitle,
                    companySalesRepStaffId: this.companyInfo.salesRepresentativeStaffID
                });
            },
            onViewOrderContactsClick() {
                this.$emit('view-order-contacts');
            },
            onViewSettlementContacts() {
                this.$emit('view-settlement-contacts');
            },
            modalSave() {
                return this.onSaveAction();
            },
            addContact(args) {
                let self = this;
                let promise = self.$api.CompaniesApi.saveContactDetail(self.contactDetail);
                return self.$rqBusy.wait(promise)
                    .then(result => {
                        self.$toast.success({ message: 'Contact Detail Saved Successfully' });
                        if(self.modalMode) {
                            return result;
                        }
                        else {
                            GlobalEventManager.saveCompleted({ success: true });
                            self.$events.emit("company-contact-list:refresh");
                            if (args?.userInitiated && args?.addAnother) {
                                self.initializeContact();
                                self.$refs.contactInfo.reset();
                            }
                            else if (args?.userInitiated) {
                                self.loadContactDetail(result);
                            }
                            else {
                                return Promise.resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        self.$toast.error(`Failed to Save Contact Detail: ${error.errorMessage}`);
                    })
                    .finally(()=>{
                        self.contactDetail.resetPassword = false;
                    });
            },
            updateContact(args) {
                let self = this;
                let changes = self.getAuditChanges(self.originalContactDetail, self.contactDetail);
                let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);
                if(changes.length > 0 || customDataChanges.length > 0) {
                    let promise = self.$api.CompaniesApi.saveContactDetail(self.contactDetail, changes);
                    return self.$rqBusy.wait(promise)
                        .then(result => {
                                self.$toast.success({ message: 'Contact Detail Saved Successfully' });
                                if(self.modalMode) {
                                    return result;
                                }
                                else {
                                    GlobalEventManager.saveCompleted({ success: true });
                                    self.$events.emit("company-contact-list:refresh");
                                    if(args && args.userInitiated) {
                                        // user initiated save
                                        self.ignoreSave = true;
                                        self.$emit("contact-update-success", {
                                            companyId: this.contactDetail.contactInfo.companyID,
                                            companyName: this.companyInfo.name,
                                            regionId: this.companyInfo.regionID,
                                            contactId: this.contactDetail.contactInfo.contactID
                                        });
                                    }
                                    else {
                                        return Promise.resolve(true);
                                    }
                                }
                        })
                        .then(result => {
                            if(!self.hasRolesId)
                                return Promise.resolve(result);

                            return self.$rqBusy.wait(self.$api.CustomDataApi.saveCustomData(customDataChanges));
                        })
                        .then(result => {
                            _.invoke(self, "$refs.customDataContainer.initialize");

                            return Promise.resolve(result);
                        })
                        .catch(error => {
                            self.$toast.error(`Failed to Update Contact Detail: ${error.errorMessage}`);
                        })
                        .finally(()=>{
                            self.contactDetail.resetPassword = false;
                        });
                }
                else {
                    self.$toast.info("No Changes Detected.");
                    return Promise.resolve(false);
                }
            },
            hasAccess(){
                if (this.addMode) return true;

                let companyRegion = _.find(this.regions, r => _.parseNumber(r.regionID) === _.parseNumber(this.companyInfo.regionID));
                let adminRegion = _.find(this.regions, r => r.isAdminRegion);
                let userHasAccessToRegion = !_.isNil(companyRegion);
                let userHasAdminRegion = !_.isNil(adminRegion);

                // Permission settings
                let allowEditCompanyDetails = this.localSecurity.AllowEditContacts

                return userHasAdminRegion || (allowEditCompanyDetails && userHasAccessToRegion);
            },
            onPcSettingsResetPassword() {
                this.contactDetail.resetPassword = true;
                this.onSaveAction();
            },
            updateFullName () {
                this.contactDetail.contactInfo.fullName = `${this.contactDetail.contactInfo.firstName || ""} ${this.contactDetail.contactInfo.lastName || ""}`.trim();
            },
            resetContactDetail() {
                this.contactDetail = {
                    contactInfo: new Contact(),
                    paperlessCloserAccess: new PaperlessCloserAccess(),
                    cdfPaperlessCloserAccess: new CDFPaperlessCloserAccess(),
                    resetPassword: false
                }
            },
            onCustomDataLoaded(e) {
                this.originalCustomData = _.cloneDeep(e);
            }
        }
    }
</script>

