<template>
    <div class="content-wrapper">
        <rq-page-section :title="`${companyName} Contacts`" header-size="lg" borderless header-only>
            <template #header-actions>
                <ul v-if="allowAdd" class="nav">
                    <li v-rq-tooltip.hover.top :title="addDisabledTooltip" class="nav-item">
                        <b-btn
                            automation_id="btn_add_contact"
                            variant="theme"
                            :disabled="!hasCompanyAccess"
                            @click="onAddContact">Add
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <b-btn
                    automation_id="btn_view_company_details"
                    variant="link"
                    class="btn-theme"
                    @click="onViewCompanyDetails">
                    View Company Details
                </b-btn>
                <b-btn v-if="showOrderLinks"
                    automation_id="btn_view_order_contacts"
                    variant="link"
                    class="btn-theme"
                    @click="$emit('view-order-contacts')">
                    View File Contacts
                </b-btn>
                <b-btn v-if="showSettlementLinks"
                    automation_id="btn_view_settlement_contacts"
                    variant="link"
                    class="btn-theme"
                    @click="$emit('view-settlement-contacts')">
                    View CDF Contacts
                </b-btn>
            </template>
        </rq-page-section>
        <contact-grid
            ref="contactGrid"
            :company-id="companyId"
            @add="onAddContact"
            @edit="onEditContact"
            @row-double-click="onEditContact"
            persist-state
            allow-edit
            allow-delete
        />
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import ContactGrid from "./ContactGrid";
    export default {
        name: "CompanyContacts",
        props: {
            companyId: { type: Number, default: 0 },
            regionId: { type: Number, default: 0 },
            companyName: { type: String, default: null },
            companySalesRepStaffId: { type: Number, default: null },
            showOrderLinks: { type: Boolean, default: false },
            showSettlementLinks: { type: Boolean, default: false },
            allowAdd: { type: Boolean, default: false }
        },
        computed: {
            ...mapGetters([ "userDefaultRegion", "permissionService" ]),
            currentRegionId() { return _.gt(this.regionId, 0) ? this.regionId : this.userDefaultRegion.regionID; },
            hasCompanyAccess() { return this.permissionService.hasCompanyAccess(this.currentRegionId); },
            addDisabledTooltip() { return this.hasCompanyAccess ? "" : "Access restricted for the seleted company's region."; }
        },
        components: { ContactGrid },
        methods: {
            onAddContact() { this.$emit("add-contact", { companyId: this.companyId, companyName: this.companyName, regionId: this.regionId, companySalesRepStaffId: this.companySalesRepStaffId }); },
            onEditContact(e) { this.$emit("view-contact-detail", { contactId: e.data.contactID, companyId: this.companyId }); },
            onViewCompanyDetails() { this.$emit("view-company-detail", { companyId: this.companyId }); }
        }
    }
</script>
