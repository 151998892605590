<template>
    <div class="company-info underwriter-detail">
        <fieldset :disabled="!hasCompanyAccess || readOnly">
            <div class="row">
                <div class="col col-12 col-lg-3">
                    <rq-image-preview
                        id="img_company_logo"
                        ref="companyLogo"
                        label="Logo"
                        v-model="localLogo"
                    />
                </div>
                <div class="col col-12 col-lg-6 form-group" :class="notesClass">
                    <label for="ta_company_notes">Notes</label>
                    <textarea id="ta_company_notes"
                        automation_id="ta_company_notes"
                        placeholder="Enter notes here..."
                        class="form-control co-notes"
                        v-model="localInfo.notes">
                    </textarea>
                </div>
                <div class="col col-12 col-lg-3 form-group" v-if="localInfo.isPlaceOfClosing">
                    <label>Locations</label>
                    <rq-list-manager
                        v-model:data-source="localLocations"
                        ref="listManager"
                        new-item-placeholder='Type Location and Press Enter...'
                        @add-item="onAddLocation"
                        @update-item="onUpdateLocation"
                        @delete-items="onDeleteLocation"
                    />
                </div>
            </div>
        </fieldset>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import RqListManager from "@/shared/components/rq/list-box/RqListManager";
    import { Company, LogoDto } from "@order-entry/contacts/models";

    export default {
        components: { RqListManager },
        props: {
            info: { type: Object, default: () => ({}) },
            locations: { type: Array, default: () => [] },
            logo: { type: Object, default: () => ({}) },
            hasCompanyAccess: { type: Boolean, default: true },
            readOnly: { type: Boolean, default: false }
        },

        data: function() {
            return {
                localInfo: {},
                localLocations: [],
                localLogo: {},
            }
        },
        created() {
            if (_.isNil(this.localInfo.regionID)) this.setDefaultRegion();
            this.localLocations = this.locations;
        },
        computed: {
            notesClass() {
                return this.localInfo.isPlaceOfClosing ? "col-3" : "col-9"
            },
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "currentSession"
            ]),
        },
        watch: {
            logo: {
                handler: function(newValue, oldValue) {
                    if(_.isEqual(_.toPlainObject(newValue), _.toPlainObject(oldValue))) return;
                    this.localLogo = new LogoDto(newValue);
                },
                deep: true
            },
            localLogo: {
                handler: function(newValue, oldValue) {
                    this.$emit("update:logo", newValue);
                },
                deep: true
            },
            info: {
                handler: function(newValue, oldValue) {
                    if(_.isEqual(_.toPlainObject(newValue), _.toPlainObject(oldValue))) return;
                    this.localInfo = new Company(newValue);
                    this.getCounties();
                },
                immediate: true,
                deep: true
            },
            localInfo: {
                handler: function(newValue, oldValue) {
                    this.$emit("update:info", newValue);
                },
                deep: true
            },
            locations: {
                handler: function(newValue, oldValue) {
                    if(this.isListEqual(newValue, oldValue)) return;
                    this.localLocations = newValue;
                },
                deep: true
            },
            localLocations: {
                handler: function(newValue, oldValue) {
                    this.$emit("update:locations", newValue);
                },
                deep: true
            },
        },
        methods:{
            getCounties () {
                const self = this;
                if (!self.localInfo || !self.localInfo.state) return;
                self.counties = self.lookupHelpers.getCountiesByState(self.localInfo.state);
            },
            onAddLocation(e) {
                this.localLocations.push({
                    itemID: this.generateRandomId(),
                    itemName: e.value
                });
            },
            onUpdateLocation(e) {
                e.commit(e.data);
            },
            onDeleteLocation(e) {
                e.commit();
            },
            isListEqual(list1, list2) {
                return _.isEqual(_.sortBy(list1), _.sortBy(list2))
            },
            generateRandomId() {
                return _.parseInt((_.uniqueId())) * -1;
            },
            setDefaultRegion() {
                this.localInfo.regionID = _.get(this.currentSession, "user.regionID", 0);
            }
        }
    }
</script>

<style lang="scss">
    .underwriter-detail {
        .stdLng-SchD { min-height: 275px; }
        .co-notes { min-height: 275px; }
    }
</style>
