import {
    CompanyEdit,
    ContactEdit,
    CompanyContactList
} from "./components/";
import { RoleType, companyContactRouteNames} from "@/shared/models/enums";

let pages = [
    {
        name: companyContactRouteNames.ManageCompanies,
        path: "",
        component: CompanyContactList,
        props: route => ({
            allowAdd: true,
            allowEdit: true,
            allowDelete: true,
            allowActivate: true,
            showRegion: true,
            excludeRoleTypes: [
                RoleType.Bank,
                RoleType.TitleCompany,
                RoleType.Underwriter
            ],
            selectedCompanyKeys: [-1],
            selectedContactKeys: [-1],
            targetCompany: route.params.targetCompanyId != null ? { companyId: _.get(route, "params.targetCompanyId", {}), regionId: _.get(route, "params.targetCompanyRegionId", {}), companyName: _.get(route, "params.targetCompanyName", {}), companySalesRepStaffId: _.get(route, "params.companySalesRepStaffId", {})  } : {}
        }),
        meta: {
            extension: false,
            label: "Company Contact List",
            logRouteAccess: true,
            sectionTitle: "",
            skipSaveOnLeave: true,
            include: false
        }
    },
    {
        path: "company/:companyId?",
        name: "dashboard:mc:company",
        component: CompanyEdit,
        props: route => ({
            companyId: _.getNumber(route, "params.companyId", 0),
            showActions: true,
            showCompanyLinks: true
        }),
        meta: {
            extension: false,
            label: "Company",
            logRouteAccess: true,
            skipSaveOnLeave: true,
        }
    },
    {
        path: "contact/:contactId?",
        name: "dashboard:mc:contact",
        component: ContactEdit,
        props: route => ({
            contactId: _.getNumber(route, "params.contactId", 0),
            companyId: _.getNumber(route, "params.companyId", 0),
            companyName: _.get(route, "params.companyName", ""),
            companySalesRepStaffId: _.getNumber(route, "params.companySalesRepStaffId", null),
            showActions: true,
            showCompanyLinks: true
        }),
        meta: {
            extension: false,
            label: "Contact",
            logRouteAccess: true,
            skipSaveOnLeave: true,
        }
    }
];

export default pages;