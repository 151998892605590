<template>
    <div class="company-contact-manager content-wrapper h-100">

        <rq-page-section :no-header="noHeader" :title="sectionTitle" header-size="lg" borderless>
            <div class="row">
                <div class="col col-3 form-group">
                    <label class="form-control-label" for="txt_company_contact_lookup_search">Search:</label>
                    <rq-search-input-group
                        id="txt_manage_companies_search"
                        placeholder="Search..."
                        tabindex="0"
                        :disabled="!searchEnabled"
                        v-model="searchValue"
                        @search="onSearchChange"
                        @clear="onSearchChange"
                        show-search-button
                        search-on-enter
                    />
                </div>
                <div v-if="showInactive || allowActivate" class="col col-12 col-md-6 col-lg-3 pt-4">
                    <b-form-checkbox
                        automation_id="chk_include_inacive"
                        id="chk_include_inacive"
                        v-model="showInactiveColumn"
                       >Include Inactive</b-form-checkbox>
                </div>
                <slot name="search-row"></slot>
            </div>
        </rq-page-section>
        <RqTabs
            :tabs="tabItems"
            v-model="activeTabIndex"
            @close-tab="onCloseTab"
            @changed="onTabsChanged">
            <template
                v-if="!hideCompanyTab"
                #companies>
                <company-grid
                    ref="companyGrid"
                    title="Companies"
                    :search-term="searchValue"
                    :region-id="currentRegionId"
                    :specific-region-only="specificRegionOnly"
                    v-model:selected-keys="selectedCompanyIds"
                    v-model:total-count="companyCount"
                    :include-role-types="includeRoleTypes"
                    :exclude-role-types="excludeRoleTypes"
                    :allow-add="allowAdd"
                    :allow-edit="allowEdit"
                    :allow-delete="allowDelete"
                    :allow-activate="allowActivate"
                    v-model:is-viewing-title-company="isViewingTitleCompany"
                    :show-inactive="showInactive"
                    :show-inactive-column="showInactiveColumn"
                    :single-select="singleSelect"
                    :additional-optional-columns="['isSettlementAgent', 'isPlaceOfClosing', 'friendlyName']"
                    :additional-visible-columns="additionalColumns"
                    :show-view-contacts="!hideContactTab"
                    :persist-filters="!dialogMode"
                    @add="onAddCompany"
                    @edit="onEditCompany"
                    @view-contacts="onViewCompanyContacts"
                    @row-double-click="onGridRowDoubleClick"
                    @reset="onGridReset"
                    persist-state
                    hide-search
                />
            </template>
            <template
                v-if="!hideContactTab"
                #contacts>
                <contact-grid
                    ref="contactGrid"
                    title="Contacts"
                    :search-term="searchValue"
                    :region-id="currentRegionId"
                    :specific-region-only="specificRegionOnly"
                    v-model:selected-keys="selectedContactIds"
                    v-model:total-count="contactCount"
                    :include-role-types="includeRoleTypes"
                    :exclude-role-types="excludeRoleTypes"
                    :allow-add="allowAdd"
                    :allow-edit="allowEdit"
                    :allow-delete="allowDelete"
                    :allow-activate="allowActivate"
                    :show-inactive="showInactive"
                    :show-inactive-column="showInactiveColumn"
                    :single-select="singleSelect"
                    :additional-optional-columns="['friendlyName']"
                    :additional-visible-columns="additionalColumns"
                    :persist-filters="!dialogMode"
                    @add="onAddContact"
                    @edit="onEditContact"
                    @row-double-click="onGridRowDoubleClick"
                    @reset="onGridReset"
                    show-back-to-companies
                    persist-state
                />
            </template>
            <template
                v-if="companyContactsTab.enabled"
                #company-contacts>
                <contact-grid
                    :key="companyContactsTab.componentKey"
                    ref="companyContactGrid"
                    title="Contacts"
                    :company-id="companyContactsTab.companyId"
                    :region-id="companyContactsTab.regionId"
                    v-model:selected-keys="companyContactsTab.selectedKeys"
                    v-model:total-count="companyContactsTab.contactCount"
                    :allow-add="allowAdd"
                    :allow-edit="allowEdit"
                    :allow-delete="allowDelete"
                    :allow-activate="allowActivate"
                    :show-inactive="showInactive"
                    :show-inactive-column="showInactiveColumn"
                    :single-select="singleSelect"
                    :additional-optional-columns="['friendlyName']"
                    :disable-add-contact-button="disableAddContactButton"
                    @add="onAddContact"
                    @edit="onEditContact"
                    @row-double-click="onGridRowDoubleClick"
                    @reset="onGridReset"
                    show-back-to-companies
                />
            </template>
        </RqTabs>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import CompanyGrid from "./CompanyGrid.vue";
    import ContactGrid from "./ContactGrid.vue";
    import { companyContactRouteNames} from "@/shared/models/enums";

    class CompanyContactTab {
        constructor(options) {
            this.componentKey = _.uniqueId();
            this.companyId = _.getNumberFrom(options, ["companyID", "companyId"], 0);
            this.companyName = _.getFrom(options, ["name", "companyName"], "");
            this.regionId = _.getNumberFrom(options, ["regionID", "regionId"], 0);
            this.companySalesRepStaffId = _.getNumberFrom(options, ["salesRepresentativeStaffID", "companySalesRepStaffId"], null);
            this.contactCount = _.getNumber(options, "contactCount", -1);
            this.selectedKeys = _.get(options, "selectedKeys", null) || [];
            this._enabled = _.gt(this.companyId, 0);
        }
        get companyInfo() { return _.pick(this, ["companyId","companyName","regionId","companySalesRepStaffId"]); }
        get enabled() { return _.gt(this.companyId, 0) && this._enabled; }
        set enabled(val) { this._enabled = _.parseBool(val); }
    }

    export default {
        name: "CompanyContactList",
        props: {
            title: { type: String, default: null },
            searchTerm: { type: String, default: null },
            tabIndex: { type: Number, default: -1 },
            regionId: { type: Number, default: null },
            targetCompany: { type: Object, default: () => ({}) },
            includeRoleTypes: { type: Array, default: () => [] },
            excludeRoleTypes: { type: Array, default: () => [] },
            selectedCompanyKeys: { type: Array, default: () => [] },
            selectedContactKeys: { type: Array, default: () => [] },
            allowAdd: { type: Boolean, default: false },
            allowEdit: { type: Boolean, default: false },
            allowDelete: { type: Boolean, default: false },
            allowActivate: { type: Boolean, default: false },
            showInactive: { type: Boolean, default: false },
            showRegion: { type: Boolean, default: false },
            singleSelect: { type: Boolean, default: false },
            hideCompanyTab: { type: Boolean, default: false },
            hideContactTab: { type: Boolean, default: false },
            dialogMode: { type: Boolean, default: false },
            showSelectionSummary: {type: Boolean, default: false},
            specificRegionOnly: {type: Boolean, default: false},
        },
        components: { CompanyGrid, ContactGrid },
        data: function() {
            const self = this;
            return {
                searchValue: self.searchTerm,
                companyCount: 0,
                contactCount: 0,
                activeTabIndex: 0,
                selectedCompanyIds: self.validKeys(self.selectedCompanyKeys),
                selectedContactIds: self.validKeys(self.selectedContactKeys),
                companyContactsTab: new CompanyContactTab(),
                showInactiveColumn: false,
                isViewingTitleCompany: false
            }
        },
        computed: {
            ...mapState({ orderSummary: state => state.orders.orderSummary }),
            ...mapGetters([
                "permissionService",
                "userDefaultRegion"
            ]),
            sectionTitle() { return this.title || this.$route.meta.sectionTitle; },
            noHeader() { return _.isEmpty(this.sectionTitle); },
            contactTabTitle() { return `Contacts (${this.contactCount})`; },
            companyTabTitle() { return `Companies (${this.companyCount})`; },
            searchEnabled() { return this.activeTabIndex !== this.tabIndexes.CompanyContactTab; },
            targetContactGrid() { return this.activeTabIndex === this.tabIndexes.CompanyContactTab ? "companyContactGrid" : "contactGrid"; },
            tabIndexes() {
                return {
                    CompanyTab: this.hideCompanyTab ? -1 : 0,
                    ContactTab: this.hideCompanyTab ? 0 : this.hideContactTab ? -1 : 1,
                    CompanyContactTab: this.hideCompanyTab || this.hideContactTab ? 1 : 2
                };
            },
            componentRefs() {
                let refList = ["companyGrid", "contactGrid", "companyContactGrid"];
                if(this.hideCompanyTab) refList.shift();
                if(this.hideContactTab) refList.splice(1, 1);
                return refList;
            },
            currentRegionId() {
                if(_.gt(this.regionId, 0)) return this.regionId;

                let routeOrderId = _.getNumber(this, "$route.params.orderId", 0);
                let orderRegionId = _.getNumber(this, "orderSummary.regionID", 0);
                return _.gt(routeOrderId,0) && _.gt(orderRegionId,0)
                    ? orderRegionId
                    : this.userDefaultRegion.regionID;
            },

            additionalColumns() { return this.showRegion ? ["regionID"] : []; },
            disableAddContactButton() {
                let currentRouteName = _.get(this.$route, "name", "");
                return companyContactRouteNames.ManageCompanies === currentRouteName && this.isViewingTitleCompany;
            },
            tabItems() {
                let companyContactsLoading = this.companyContactsTab.contactCount < 0;
                let companyContactsTitle = companyContactsLoading
                    ? this.companyContactsTab.companyName
                    : `${this.companyContactsTab.companyName} (${this.companyContactsTab.contactCount})`;

                return [
                    { automation_id: "btn_tab_companies", title: this.companyTabTitle, name: "companies", visible: !this.hideCompanyTab },
                    { automation_id: "btn_tab_contacts", title: this.contactTabTitle, name: "contacts", visible: !this.hideContactTab },
                    { automation_id: "btn_tab_company_contacts", title: companyContactsTitle, name: "company-contacts", visible: this.companyContactsTab.enabled, isLoading: companyContactsLoading, showClose: true }
                ];
            }
        },

        watch: {
            activeTabIndex(newValue, oldValue){
                this.$nextTick(() => {
                    this.syncContactKeys();
                    this.invokeActiveGridMethod("updateDimensions");
                    this.$emit("update:tabIndex", newValue);
                });
            },
            tabIndex: {
                handler(newValue, oldValue) {
                    let newIndex = _.parseNumber(newValue, 0);
                    let oldIndex = _.parseNumber(oldValue, 0);
                    if(newIndex === oldIndex || newIndex < 0 || newIndex === this.activeTabIndex) return;
                    this.activeTabIndex = newIndex;
                },
                immediate: true
            },
            targetCompany: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || _.isEmpty(newValue)) return;
                    this.setCompanyContactsTab(newValue);
                },
                immediate: true
            },
            selectedCompanyKeys(newValue, oldValue) {
                let keys = this.validKeys(newValue);
                this.selectedCompanyIds = [...keys];
            },
            selectedContactKeys(newValue, oldValue) {
                let keys = this.validKeys(newValue);
                if(this.activeTabIndex === this.tabIndexes.CompanyContactTab)
                    this.companyContactsTab.selectedKeys = [...keys];
                else
                    this.selectedContactIds = [...keys];
            },
            selectedCompanyIds(newValue, oldValue) {
                if(!this.differs(newValue, this.selectedCompanyKeys)) return;
                this.syncProp("selectedCompanyKeys", newValue);
            },
            selectedContactIds(newValue, oldValue) {
                if(!this.differs(newValue, this.selectedContactKeys)) return;
                this.syncProp("selectedContactKeys", newValue);
            },
            "companyContactsTab.selectedKeys"(newValue, oldValue) {
                if(!this.differs(newValue, this.selectedContactKeys)) return;
                this.syncProp("selectedContactKeys", newValue);
            },
        },
        created() {
            this.init();
        },

        beforeUnmount() {
            const self = this;
            self.$events.off("company-contact-list:refresh");
        },

        methods: {
            onSearchChange() { this.invokeGridMethodOnAll("refresh"); },
            onAddCompany() { this.$emit("view-company-detail"); },
            onAddContact(e) { this.$emit("view-contact-detail", { ...this.companyContactsTab.companyInfo, tabIndex: this.activeTabIndex }); },
            onEditCompany(e) { this.$emit("view-company-detail", { companyId: e.data.companyID }); },
            onEditContact(e) { this.$emit("view-contact-detail", { contactId: e.data.contactID, companyId: e.data.companyID, tabIndex: this.activeTabIndex }); },
            onViewCompanyContacts(e) { this.setCompanyContactsTab(_.get(e, "data", {})); },
            onBackToCompanies() { this.activeTabIndex = this.tabIndexes.CompanyTab; },

            onGridRowDoubleClick(e) {
                if(this.dialogMode)
                    this.$emit("row-double-click", e);
                else if(e.name === "company")
                    this.onEditCompany(e);
                else if(e.name === "contact")
                    this.onEditContact(e);
            },

            onGridReset() {
                this.showInactiveColumn = false;
            },

            init(){
                const self = this;

                self.$events.on("company-contact-list:refresh", () => self.refreshAll());
                self.activeTabIndex = self.tabIndex >= 0
                    ? self.tabIndex
                    : _.some(self.selectedContactKeys, k => _.gt(k, 0))
                        ? self.tabIndexes.ContactTab
                        : self.tabIndexes.CompanyTab;

                if(!self.showSelectionSummary) return;

                self.activeTabIndex = 0;
                self.selectedCompanyIds = self.selectedContactIds = [];
            },

            //onActivateTab(newTabIndex, prevTabIndex, bEvt) {
                // this.$nextTick(() => {
                //     this.syncContactKeys();
                //     this.invokeActiveGridMethod("updateDimensions");
                //     this.$emit("update:tabIndex", newTabIndex);
                // });
            //},

            onTabsChanged(currentTabs, previousTabs) {
                if(!this.companyContactsTab.enabled || currentTabs.length <= previousTabs.length) return;
                this.$nextTick(() => {
                    this.activeTabIndex = currentTabs.length - 1;
                });
            },

            onCloseTab(e) {
                if(e.tab.name !== "company-contacts") return;
                this.companyContactsTab.enabled = false;
            },

            syncContactKeys() {
                if(this.activeTabIndex === this.tabIndexes.ContactTab) {
                    this.syncProp("selectedContactKeys", this.selectedContactIds);
                }
                else if(this.activeTabIndex === this.tabIndexes.CompanyContactTab) {
                    this.syncProp("selectedContactKeys", this.companyContactsTab.selectedKeys);
                }
            },

            setCompanyContactsTab(companyObj) {
                let newTab = new CompanyContactTab({ ...companyObj, contactCount: -1 });
                if(newTab.enabled && this.companyContactsTab.companyId === newTab.companyId) {
                    this.companyContactsTab.enabled = true;
                }
                else {
                    this.companyContactsTab = newTab;
                }
                if(this.activeTabIndex === this.tabIndexes.CompanyContactTab) return;
                this.$nextTick(() => {
                    this.activeTabIndex = this.tabIndexes.CompanyContactTab;
                });
            },

            validKeys(keys) { return !_.isNil(keys) && _.isArray(keys) ? _.filter(keys, k => _.gt(k,0)) : []; },
            differs(arr1, arr2) { return arr1.length !== arr2.length || _.some(_.difference(arr1,arr2)); },
            syncProp(propName, propVal) { this.$emit(`update:${propName}`, propVal); },
            getSelectedCompanies() { return this.invokeGridMethod("companyGrid", "getSelectedData"); },
            getSelectedContacts() { return this.invokeGridMethod(this.targetContactGrid, "getSelectedData"); },
            refreshCompanies() { return this.invokeGridMethod("companyGrid", "refresh"); },
            refreshContacts() { return this.invokeGridMethod(this.targetContactGrid, "refresh"); },
            refreshAll() { return this.invokeGridMethodOnAll("refresh"); },
            invokeGridMethod(componentRef, method, args=null) { return _.invoke(this, `$refs.${componentRef}.${method}`, args); },
            invokeActiveGridMethod(method, args=null) { this.invokeGridMethod(this.componentRefs[this.activeTabIndex], method, args); },
            invokeGridMethodOnAll(method, args=null) {
                if(!this.hideCompanyTab) this.invokeGridMethod("companyGrid", method, args);
                if(!this.hideContactTab) this.invokeGridMethod("contactGrid", method, args);
                if(this.companyContactsTab.enabled) this.invokeGridMethod("companyContactGrid", method, args);
            }
        }
    }
</script>